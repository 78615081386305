import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { BlogBrowser } from '../components/BlogBrowser';
import BlogHero from '../components/BlogHero';
import SiteMeta from '../components/SiteMeta';
import EmailBanner from '../components/EmailBanner';

const BlogPage = ({ data }) => {
  const {
    allContentfulBlog,
    contentfulBlogFeatured: {
      blogHero,
      metaTitle,
      metaDescription: { metaDescription },
    },
    contentfulHomepage: {
      mailingListCalloutText,
      emailForm,
    }
  } = data;

  const emailBannerData = {
    title: mailingListCalloutText,
    form: emailForm,
  };


  const resources = allContentfulBlog.edges.map((n) => n.node);
  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <BlogHero {...blogHero} />
      <BlogBrowser resources={resources} />
      <EmailBanner theme="yellow" {...emailBannerData} />
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
  query {
    contentfulBlogFeatured {
      metaTitle
      metaDescription {
        metaDescription
      }
      blogHero {
        ...Navigator
      }
    }
    allContentfulBlog(filter: {microsite: {ne: "wfe"}, slug: {ne: "dummy-resource"}}) {
      edges {
        node {
          ...BlogCard
        }
      }
    }
    contentfulHomepage {
      mailingListCalloutText
      emailForm {
        ...Form
      }
    }
  }
`;